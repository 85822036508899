<template>
  <component
    :is="componentIs"
    class="sf-menu-item"
    v-bind="bind"
    :data-testid="label"
    v-on="$listeners"
  >
    <slot name="icon" />
    <slot name="label" v-bind="{ label }">
      <span class="sf-menu-item__label">{{ label }}</span>
    </slot>
    <slot name="mobile-nav-icon" v-bind="{ icon }">
      <SfIcon
        :class="{ 'display-none': !icon }"
        :icon="icon"
        class="sf-menu-item__mobile-nav-icon smartphone-only"
        size="14px"
      />
    </slot>
  </component>
</template>

<script>
import { SfIcon, SfLink, SfButton } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HvMenuItem',
  components: {
    SfIcon,
    SfLink,
    SfButton,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: [String, Array],
      default: 'chevron_right',
    },
    count: {
      type: [String, Number],
      default: '',
    },
    link: {
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    bind() {
      const bind = {};
      if (this.link) {
        bind.link = this.link;
      } else {
        bind.class = 'hv-title';
      }
      return bind;
    },
    componentIs() {
      return this.link ? 'SfLink' : 'div';
    },
  },
});
</script>

<style lang="scss">
@import '@storefront-ui/shared/styles/components/molecules/SfMenuItem.scss';

.search__categories {
  .sf-menu-item {
    &.hv-title {
      .sf-menu-item__label {
        cursor: default;
        margin: var(--mega-menu-column-header-margin, var(--spacer-sm) 0);
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
  .sf-link {
    &:hover {
      text-decoration: underline solid var(--c-primary);
    }
    .sf-menu-item__label {
      display: inline-block;
      transition: transform 0.3s cubic-bezier(0.4, 1, 0.8, 1);
      padding: 0 5px;
      &:hover {
        transform: scale3d(1.1, 1.1, 1.1);
      }
    }
  }
  .sf-mega-menu-column__content {
    margin: var(--spacer-2xs) 0;
    padding: var(--product-card-padding, var(--spacer-xs));
  }
}
.search__results {
  .sf-menu-item {
    &.hv-title {
      .sf-menu-item__label {
        cursor: default;
        font-weight: bold;
      }
    }
  }
}
</style>
